<template>
<div class="container">
    <div id="content"></div>
  </div>
</template>

<script>

export default {
  components: {
  },
  mounted() {
      // https://john-dugan.com/javascript-debounce/
function debounce(e, t, n) {
  var a;
  return function () {
    var r = this,
      i = arguments,
      o = function () {
        a = null, n || e.apply(r, i)
      },
      s = n && !a;
    clearTimeout(a), a = setTimeout(o, t || 200), s && e.apply(r, i)
  }
}

const texts = [
    `<div class="services__offres__text"><h3 class="services__offres__title">CREATION DE CONTENU</h3><p class="services__offres__text">La création de contenu est une pratique qui consiste à concevoir des supports (photos, vidéos, infographies...) pour les partager sur vos différents médias en ligne. Ils permettent de fidéliser votre clientèle en la mettant en valeur et/ou en la captivant, tout en vous donnant l'opportunité d'atteindre de nouveaux clients. Je vous propose une formule à carte qui contiendra, en fonction d’une stratégie marketing développée ensemble, des contenus à partager de façon régulière avec vos clients.</p></div><div class="services__offres__dotblock"><i class="fas fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></div>`,
    `<div class="services__offres__text"><h3 class="services__offres__title">INFOGRAPHIE</h3><p class="services__offres__text">Les infographies sont un des supports de communication les plus efficaces en marketing de contenu. C'est une représentation visuelle de données et d’informations, d'où son nom info-graphie. Elle combine plusieurs éléments (textes, graphiques, formes, images...) de façon lisible et agréable à regarder, tout en synthétisant le message à transmettre au public. Les infographies peuvent être utilisées dans de nombreuses occasions : pour reprendre une information, synthétiser des données, teaser un événement ou un produit, créer de la viralité sur les réseaux sociaux... Vous en croisez quotidiennement !</p></div><div class="services__offres__dotblock"><i class="far fa-circle"></i><i class="fas fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></div>`,
    `<div class="services__offres__text"><h3 class="services__offres__title">PHOTOS</h3><p class="services__offres__text">Une image vaut mille mots ! Les photographies sont une pièce essentielle du marketing de contenu : elles permettent d'attirer l'attention de votre client, de le captiver et de générer des interactions et de la viralité. Elles permettent d'entretenir une relation de proximité avec votre clientèle, en montrant une image authentique de votre entreprise : nouveaux produits, travaux en cours, coulisses, saisonnalité... Les occasions ne manquent pas pour communiquer par la photographie ! LM Créations Numériques vous proposera une stratégie de contenu photographique, ainsi que leur réalisation avec la qualité pour priorité.</p></div><div class="services__offres__dotblock"><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="fas fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></div>`,
    `<div class="services__offres__text"><h3 class="services__offres__title">VIDEOS</h3><p class="services__offres__text">Les vidéos sont le contenu avec le potentiel viral le plus fort, qui peuvent vous permettre de toucher des milliers de clients potentiels. Les teasers, ou spots marketing très courts (moins de 30 secondes) règnent sur les réseaux sociaux, qu’ils soient en prises de vues réelles ou en motion design. Ils permettent de faire passer un message fort en allant droit au but et en incitant votre public à en voir plus. Plus longs, vous trouverez également des clips, films institutionnels, témoignages... à découvrir sur notre page vidéo !</p></div><div class="services__offres__dotblock"><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="fas fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i></div>`,
    `<div class="services__offres__text"><h3 class="services__offres__title">MAILING</h3><p class="services__offres__text">Le mailing consiste à envoyer des mails d'informations (plus connus sous le nom de newsletter) afin de prospecter mais aussi de fidéliser votre clientèle. Il faut tout d'abord collecter une base client avec leurs adresses email, tout en respectant le RGPD (Règlement Général sur la Protection des Données), pour ensuite leur envoyer les newsletters. Mais attention ! Il ne faut pas en abuser, au risque d'être considéré comme un spammeur. C'est pourquoi les newsletters doivent avoir un objectif marketing et une rédaction et des contenus soignés.</p></div><div class="services__offres__dotblock"><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="fas fa-circle"></i><i class="far fa-circle"></i></div>`,
    `<div class="services__offres__text"><h3 class="services__offres__title">CONTENT MANAGEMENT OU COMMUNITY MANAGEMENT ?</h3><p class="services__offres__text">Il existe plusieurs types de marketing sur les réseaux sociaux, parmi eux, le content management et le community management. Le content management est la gestion des contenus publiés sur vos réseaux sociaux. Il ne faut pas le confondre avec le community management qui, lui, concerne à la fois la gestion des contenus et la gestion de votre communauté (modérer et répondre aux commentaires, aux messages…). Les offres que je vous propose sont du content management et du marketing de contenu. Mon rôle est de vous produire du contenu de qualité pour vos réseaux, suivant une stratégie marketing établie ensemble, tout en vous laissant l'autonomie de construire une relation de confiance avec vos abonnés. N'hésitez pas néanmoins à me demander des conseils !</p></div><div class="services__offres__dotblock"><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="fas fa-circle"></i></div>`,
    `<div class="services__offres__text"><h3 class="services__offres__title">CREATIONS NUMERIQUES</h3><p class="services__offres__text">Pour les particuliers et même pour les professionnels, je propose également des créations numériques en tous genre comme la réalisation de photocollage de vos animaux en personnages médiévaux, l’intégration de sous-titres à vos vidéos, l’animation 2D de logo, etc. Vous trouverez ces prestations à la carte sur ComeUp (ex 5euros.com) mais aussi sur mesure en me contactant !</p></div><div class="services__offres__dotblock"><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="fas fa-circle"></i></div>`
  ]

const stepSize = window.innerHeight

function setText(contentTag, text) {
  contentTag.innerHTML = text
}

function getIndex(distance, stepSize) {
  return parseInt(distance / stepSize)
}

function getText(texts, index) {
  return texts[index] ? texts[index] : `<div class="services__offres__text"><h3 class="services__offres__title">CREATIONS NUMERIQUES</h3><p class="services__offres__text">Pour les particuliers et même pour les professionnels, je propose également des créations numériques en tous genre comme la réalisation de photocollage de vos animaux en personnages médiévaux, l’intégration de sous-titres à vos vidéos, l’animation 2D de logo, etc. Vous trouverez ces prestations à la carte sur ComeUp (ex 5euros.com) mais aussi sur mesure en me contactant !</p></div><div class="services__offres__dotblock"><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="fas fa-circle"></i></div>`
}

function changeText(contentTag, stepSize, texts) {
  const distance = window.scrollY -1000
  const index = getIndex(distance, stepSize)
  const text = getText(texts, index)
  setText(contentTag, text)
}

function setInittext(contentTag, texts) {
  const text = getText(texts, 0)
  setText(contentTag, text)
}

function loadText() {
  const contentTag = document.querySelector('#content')
  setInittext(contentTag, texts)
  window.addEventListener('scroll', debounce(() => { changeText(contentTag, stepSize, texts) }, 20))
}

loadText();
  }
}
</script>

<style lang="scss">


.container {
  height: auto; 
}


#content {
    width: 420px;
    color: black;
    text-align: center;
    display: flex;
    align-items: center;
}

.services__offres__dotblock {
  display: flex;
    flex-direction: column;
    margin-left: 25px;
}

.fa-circle {
  color: #26262696;
    font-size: 6px;
  margin: 5px 0;
}
</style>