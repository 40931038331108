<template>
  <div class="blockslidecc parallax-demo">
    <div data-v-2f282228="" class="curve-shape curve-bottom" id="bottom-curvecc"><svg data-v-2f282228="" x="0px" y="0px" viewBox="0 0 3841 120" xml:space="preserve" style="overflow: scroll;"><path data-v-2f282228="" d="M3360.5,97.739c-242,0-480-48.375-480-48.375
                    S2647.5,0.5,2400.5,0.5s-480,48.375-480,48.375s-238,48.864-480,48.864s-480-48.375-480-48.375S727.5,0.5,480.5,0.5
                    S0.5,48.875,0.5,48.875V108h1920h1920V48.875C3840.5,48.875,3602.5,97.739,3360.5,97.739z" style="fill: rgb(255, 255, 255); stroke-miterlimit: 10;"></path></svg></div>
    <div class="slidecontainer">
        <h2 class="slidetitle">LE PROCESS </h2>
        <div class="carousel-container">
            <div class="carousel-card" id="far-left">
                <i class="fas fa-box-open carousel-icon"></i>
                <h3 class="carousel-title">4. LIVRAISON DES CONTENUS</h3>
                <p class="carousel-text">Je vous livre via WeTransfer les contenus demandés la veille de leur publication, accompagnés de conseils marketing sur la date et l'heure de publication.</p>
            </div>
            <div class="carousel-card" id="left">
                <i class="far fa-check-square carousel-icon"></i>
                <h3 class="carousel-title">5. PUBLICATION SUR VOS RESEAUX</h3>
                <p class="carousel-text">Si vous en ressentez le besoin, je programme et publie de votre part vos contenus sur vos réseaux : Facebook, Instagram ou Twitter.</p>
            </div>
            <div class="carousel-card" id="center">
                <i class="fas fa-clipboard-list carousel-icon"></i>
                <h3 class="carousel-title">1. DEFINITION D'UNE STRATEGIE</h3>
                <p class="carousel-text">A l'aide d'un audit de votre présence sur les réseaux sociaux, le cas échéant, et de vos objectifs, nous définissons ensemble une stratégie de contenus.</p>
            </div>
            <div class="carousel-card" id="right">
                <i class="fas fa-calendar-alt carousel-icon"></i>
                <h3 class="carousel-title">2. ETABLISSEMENT D'UN CALENDRIER</h3>
                <p class="carousel-text">Suivant la stratégie établie précédemment, nous créons ensemble un calendrier personnalisé des contenus à réaliser en fonction de vos besoins.</p>
            </div>
            <div class="carousel-card" id="far-right">
                <i class="fas fa-palette carousel-icon"></i>
                <h3 class="carousel-title">3. CREATION DES CONTENUS</h3>
                <p class="carousel-text">Suivant le calendrier, je viens tourner, photoshooter ou je réalise le montage ou le motion design ou infographie les contenus demandés.</p>
            </div>
            </div>        
    </div>
    <div data-v-2f282229="" class="curve-shape" id="top-curvecc">
      <svg data-v-2f282229="" x="0px" y="0px" viewBox="0 0 3841 120" xml:space="preserve" style="overflow: scroll;">
        <path data-v-2f282229="" d="M3360.5,97.739c-242,0-480-48.375-480-48.375
                    S2647.5,0.5,2400.5,0.5s-480,48.375-480,48.375s-238,48.864-480,48.864s-480-48.375-480-48.375S727.5,0.5,480.5,0.5
                    S0.5,48.875,0.5,48.875V108h1920h1920V48.875C3840.5,48.875,3602.5,97.739,3360.5,97.739z" style="fill: rgb(255, 255, 255); stroke-miterlimit: 10;"></path>
        </svg>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'

export default {
  name: 'Header',
  props: {
    msg: String
  },
    mounted() {
        /********* Parralaxe */
      $.fn.parallax = function(opt) {
    return this.each(function() {
        var optDefault = {
                speed: 0.3
            },
            self = $(this),
            optDataSpeed = {
                speed: self.data('parallax-speed')
            },
            bpFirst = self.css('background-position').split(' ')[0],
            bpLast = self.css('background-position').split(' ')[1],
            opts = $.extend(optDefault, opt, optDataSpeed);
        function parallax() {
            var st = $(window).scrollTop(),
                wh = $(window).outerHeight(),
                o = self.offset(),
                h = self.outerHeight(),
                bp = bpFirst + ' ' + 'calc(' + bpLast + ' + ' + (o.top-st)*opts.speed + 'px)';
            if ( (st + wh >= o.top) && (st <= o.top + h) ) {
                self.css('background-position', bp);
            }
        }
        $(window).on('scroll', parallax);
    });
    }
    $('.parallax-demo').parallax({
        speed: 0.4
    });

    /************* Slideshow */
                (function () {
            'use strict';
            var options = ['far-left', 'left', 'center', 'right', 'far-right'];
            var cards = document.querySelectorAll('.carousel-card');
            addCardListeners();
            
            function addCardListeners () {
                for (var i = 0; i < cards.length; i++) {
                var card = cards[i];
                card.addEventListener('click', cardEventListener);
                
                }
            }
            
            function cardEventListener (e) {
                var parent = getParents(e.target, '.carousel-card')[0];
                var parentIndex = options.indexOf(parent.id);
                
                cards.forEach(function(card) {
                var index = options.indexOf(card.id);
                if (parentIndex > 2) {
                    var previousIndex = index - 1 < 0 ? cards.length -1 : index - 1;
                    card.id = options[previousIndex];
                } else if (parentIndex < 2) {
                    var nextIndex = index + 1 > cards.length - 1 ? 0 : index + 1;
                    card.id = options[nextIndex];
                }
                });
            }
            
            function getParents(elem, selector) {
                // Element.matches() polyfill
                if (!Element.prototype.matches) {
                Element.prototype.matches =
                    Element.prototype.matchesSelector ||
                    Element.prototype.mozMatchesSelector ||
                    Element.prototype.msMatchesSelector ||
                    Element.prototype.oMatchesSelector ||
                    Element.prototype.webkitMatchesSelector ||
                    function(s) {
                    var matches = (this.document || this.ownerDocument).querySelectorAll(s),
                        i = matches.length;
                    while (--i >= 0 && matches.item(i) !== this)
                    return i > -1;
                    };
                }

                // Setup parents array
                var parents = [];

                // Get matching parent elements
                for ( ; elem && elem !== document; elem = elem.parentNode ) {

                // Add matching parents to array
                if ( selector ) {
                    if ( elem.matches( selector ) ) {
                    parents.push( elem );
                    }
                } else {
                    parents.push( elem );
                }
                }

                return parents;
                }
            })();
    }
}
</script>

<style lang="scss">

/* Carousel */
.about-us-service-title {
  text-align: center;
}

.carousel-container {
  padding: 20px;
  display: flex;
  text-align: center;
  position: relative;
  height: 450px;
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}

.carousel-title {
    font-family: 'Yanone Kaffeesatz';
    margin-bottom: 20px;
    font-size: x-large;
    @media (max-width: 768px) {
		font-size: medium;
	}
}

.carousel-text {
  text-align: left;
  font-size: smaller;
  @media (max-width: 768px) {
		font-size: small;
	}
  @media (max-width: 325px) {
		font-size: x-small;
	}
}

.carousel-card {
  height: 300px;
  width: 30%;
  padding: 20px;
  transition: 1s;
  opacity: 1;
  position: absolute;
  top: 15;
  background-color: white;
  color: darkslategray;
  cursor: pointer;
  box-shadow: 1px 2px 3px grey;
  border-radius: 5px;
  overflow: hidden;
  @media (max-width: 768px) {
		width: 50%;
	}
}

.carousel-icon {
  font-size: xxx-large;
  background: linear-gradient(308deg, #1c09e0cf 0%, #38e01fc7 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;
}

#far-left {
  left: 15%;
  transform: scale(.9) translateY(0%) translateX(-50%);
  z-index: 5;
  background-color: #2db5a4;
  opacity: 0.5;
}

#left {
  left: 30%;
  transform: scale(1) translateY(0%) translateX(-50%);
  z-index: 10;
  background-color: #b4dcd9;
  opacity: 0.8;
}

#center {
  left: 50%;
  transform: scale(1.05) translateY(0%) translateX(-50%);
  z-index: 15;
}

#right {
  left: 70%;
  transform: scale(1) translateY(0%) translateX(-50%);
  z-index: 10;
  background-color: #b1d5f6;
  opacity: 0.8;
}

#far-right {
  left: 80%;
  transform: scale(.9) translateY(0%) translateX(-50%);
  z-index: 5;
  background-color: #168acf;
  opacity: 0.5;
}

#farfar-right {
  left: 80%;
  transform: scale(.9) translateY(0%) translateX(-50%);
  z-index: 4;
  background-color: #168acf;
  opacity: 0;
}

/**************** VAGUES */


@keyframes move-left-data-v-2f282228 {
    0% {
        left: 0;
    }
    to {
        left: -100%;
    }
}

@keyframes move-left-data-v-2f282229 {
    0% {
        left: 0;
    }
    to {
        left: -100%;
    }
}

.blockslidecc {
  position: relative;
  background-image: url("../assets/seignosse6.webp");
  background-size: cover;
  background-position: center;
  height: 750px;
  margin-bottom: 40px;
  @media (max-width: 2280px) {
		height: 650px;
	}
  @media (max-width: 1440px) {
		height: 600px;
	}
  @media (max-width: 1024px) {
		height: 550px;
	}
  @media (max-width: 768px) {
		height: 550px;
	}
  @media (max-width: 425px) {
		height: 500px;
	}
  @media (max-width: 300px) {
		height: 500px;
	}
}

#bottom-curvecc {
    position: relative;
    top: -22px;
    margin-bottom: 0 !important;
    @media (max-width: 2280px) {
		top: -18px
	}
     @media (max-width: 1440px) {
		top: -14px;
	}
    @media (max-width: 768px) {
		top: -10px;
	}
    @media (max-width: 425px) {
		top: -8px;
	}
     @media (max-width: 300px) {
		top: -6px;
	}
   
}

#top-curvecc {
  position: absolute;
    bottom: -15px;
    width: 100%;
    @media (max-width: 2280px) {
		bottom: -7px;
	}
    @media (max-width: 1440px) {
		bottom: -3px;
	}
    @media (max-width: 768px) {
		bottom: 2px;
	}
    @media (max-width: 425px) {
		bottom: 4px;
	}

}

.curve-shape svg[data-v-2f282228] {
    width: 200%;
    position: relative;
    animation: move-left-data-v-2f282228 6s linear infinite;
}

.curve-shape svg[data-v-2f282229] {
    width: 200%;
    position: relative;
    animation: move-left-data-v-2f282229 5s linear infinite;
}

.curve-shape.curve-bottom {
    overflow: hidden;
    transform: rotateX(-180deg);
    margin-bottom: -160px!important;
}

.curve-shape[data-v-2f282228] {
    overflow-x: hidden;
}

.curve-shape[data-v-2f282229] {
    margin-bottom: -12px;
    overflow-x: hidden;
}

.slidetitle {
    font-family: 'Yanone Kaffeesatz';
    text-align: center;
    font-size: xxx-large;
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
}


.homeservices__title {
    font-family: 'Yanone Kaffeesatz', sans-serif;
    font-size: 70px;
    margin-top: 0;
    margin-bottom: 30px;
    position: relative;
    width: 100%;
    &::before {
      content: '';
    width: 100%;
    position: absolute;
    left: 0;
    top: calc( 50% - 1.5px );
    border-bottom: 30px solid #38e01f;
    z-index: -1;
    }
    @media screen and (max-width: 980px){
            margin-top: 60px;
            }
}


</style>