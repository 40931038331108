<template>
 <div class="crea__price">
       <!--<div class="table__infomobile">
        <div class="table__infomobile__block">
          <h3 class="table__infomobile__title"><img src="../assets/018-lifesaver.svg" class="table__infomobile__icon">PACK  BOUEE</h3>
          <p class="table__infomobile__text">La base pour vous lancer sur les réseaux sociaux.</p>
        </div>
        <div class="table__infomobile__block">
          <h3 class="table__infomobile__title"><img src="../assets/021-navigation.svg" class="table__infomobile__icon">PACK BOUSSOLE</h3>
          <p class="table__infomobile__text">Tout ce qu'il vous faut pour faire décoller la visibilité de vos réseaux en les alimentant régulièrement.</p>
        </div>
        <div class="table__infomobile__block">
          <h3 class="table__infomobile__title"><img src="../assets/001-anchor.svg" class="table__infomobile__icon">PACK ANCRE</h3>
          <p class="table__infomobile__text">Une gestion complète de vos contenus en ligne.</p>
        </div>
      </div> -->
      <table class="table__spec" align="center" width="41%">
          <!--<tr class="table__linedesktop">
            <td class="table__empty">
            </td>
            <td class="table__empty">
              <img src="../assets/018-lifesaver.svg" alt="Illustration" class="table__illus">
              <p class="table__title">BOUEE</p>
            </td>
            <td class="table__empty">
              <img src="../assets/021-navigation.svg" alt="Illustration" class="table__illus">
              <p class="table__title">BOUSSOLE</p>
            </td>
            <td class="table__empty">
              <img src="../assets/001-anchor.svg" alt="Illustration" class="table__illus">
              <p class="table__title">ANCRE</p>
            </td>
          </tr>-->
          <tr class="table__linedesktop">
            <td class="table__empty">
              <p>Votre besoin</p>
            </td>
            <td class="table__empty">
              <p>Tarif indicatif (à partir de)</p>
            </td>
          </tr>
          <!--<tr class="table__linemobile">
            <td class="table__empty">
            </td>
            <td class="table__empty">
              <img src="../assets/018-lifesaver.svg" alt="Illustration" class="table__infomobile__icon">
            </td>
            <td class="table__empty">
              <img src="../assets/021-navigation.svg" alt="Illustration" class="table__infomobile__icon">
            </td>
            <td class="table__empty">
              <img src="../assets/001-anchor.svg" alt="Illustration" class="table__infomobile__icon">
            </td>
          </tr>-->
          <tr>
            <td>
              <p>Elaboration d’une stratégie de contenus</p>
            </td>
            <td>
              <p>90€HT</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Tournage sur votre lieu d'activité</p>
            </td>
            <td>
              <p>90€HT</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Photographies</p>
            </td>
            <td>
              <p>45€HT</p>
            </td>
          </tr>
           <tr>
            <td>
              <p>Vidéo teaser</p>
            </td>
            <td>
              <p>150€HT</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Vidéo motion design</p>
            </td>
            <td>
              <p>150€HT</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Infographie</p>
            </td>
            <td>
              <p>45€HT</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Rédaction de publication</p>
            </td>
            <td>
              <p>25€HT</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Rédaction de newsletter</p>
            </td>
            <td>
              <p>45€HT</p>
            </td>
          </tr>

    </table>
   <!-- <table class="table__price">
      <tr>
            <td>
              <p>TARIFS</p>
            </td>
            <td>
              <p>A partir de 100€HT / mois</p>
            </td>
            <td>
              <p>A partir de 350€HT / mois</p>
            </td>
            <td>
              <p>A partir de 650€HT / mois</p>
            </td>
          </tr>
    </table>-->
    <p class="smallprint">*Ces tarifs sont donnés à titre indicatif de base pour chaque service. Le tarif définitif de votre projet sera établi sur devis contractuel après avoir analysé vos besoins et vos envies (exemple : nombre de vidéos, d'infographies, de mails...). En fonction, un prix de pack vous sera proposé. N'hésitez pas à me contacter, le devis est gratuit !</p>   
    <router-link to="Contact"><div class="devis">ME DEMANDER UN DEVIS</div></router-link>
 </div>
</template>

<script>
//import $ from 'jquery'

export default {
  name: '',
  props: {
    msg: String
  },
    mounted() {
    }
}
</script>

<style lang="scss">
.crea__price {
  background-image: url('../assets/wavej4.svg');
  background-size: auto;
    background-position-y: center;
    background-repeat: repeat-x;
  @media screen and (max-width: 768px){
            background-size: cover;
            background-position-y: 300px;
            }
}


table {
  width: 81%;
  margin: auto;
  background-origin: border-box;  
  border-spacing: 0px 1px;
}

.table__price {
  margin-top: 20px;
  font-weight: bold;
  font-size: medium;
  @media (max-width: 768px) {
		width: 90%;
    font-size: small;
	}
}

.table__spec {
  font-size: medium;
  @media (max-width: 768px) {
		width: 90%;
    font-size: x-small;
	}
}

.table__linedesktop {
  @media (max-width: 768px) {
		display: none;
	}
}

.table__linemobile {
  @media (min-width: 769px) {
		display: none;
	}
}

.table__infomobile {
  margin: auto;
  width: 90%;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  @media (min-width: 769px) {
		display: none;
	}
  @media (min-width: 650px) {
		flex-direction: row;
    justify-content: space-between;
    margin-top: 15px;
	}
  &__title {
    font-family: 'Yanone Kaffeesatz';
    display: flex;
    align-items: center;
  }
  &__icon {
    margin-right: 7px;
    height: 30px;
  }
  &__text {
    font-size: small;
  }
  &__block {
    margin-bottom: 15px;
    @media (min-width: 650px) {
		width: 30%;
	}
  }
}

td {
  background: transparent;
  text-align: center;
  height: 40px;
  width: 25%;
  font-size: inherit;
  padding: 4px 16px;
  border-top: 1px solid #386ed8;
  @media (max-width: 768px) {
		padding: 4px 0px;
	}
}

.table__illus {
  margin: auto;
  margin-bottom: 10px;
  height: 150px;
    width: auto;
}

.table__title {
  font-family: 'Yanone Kaffeesatz';
  font-size: x-large;
}

.table__empty {
  border: none;
}

.fa-check {
  background: #38e01fc7;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: initial;
}

.fa-times {
  background: #1c09e0cf;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: initial;
}

.smallprint {
  width: 90%;
    margin: auto;
    margin-top: 30px;
    font-size: x-small;
}

.devis {
    border: 2px solid #386ed8;
    width: fit-content;
    margin: auto;
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 15px 15px 12px;
    box-sizing: border-box;
    border-radius: 40px;
    font-family: 'Yanone Kaffeesatz';
    font-size: xx-large;
    transition: all 0.25s;
     &:hover {
            background-color: #386ed8;
            box-shadow: 0px 2px 5px 0px black;
            color: white!important;
            transform: scale(1.02);
        }
        &:focus {
            background-color: lighten($color: #386ed8, $amount: 30);
            box-shadow: 0px 2px 5px 0px black;
            color: white;
            transform: scale(1.02);
        }
  @media screen and (max-width: 425px){
            width: 90%;
            text-align: center;
            margin-bottom: 20px;
            }
}
</style>